<template>
  <div>
    <div >
      <CompTV2
          :pProductsTV2="pProductsTV2"
      />
    </div>
<!--    <div v-if="!showTV">-->
<!--      <CompTV3-->
<!--          :pProductsTV3="pProductsTV3"-->
<!--      />-->
<!--    </div>-->
  </div>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player';
// import Video from "../assets/production ID_4042728.mp4";
import Flamme1 from "../assets/Flamme3.mp4";
import logo from "../assets/koc_logo.png";
import CompTV2 from "@/components/CompTV2";
import CompTV3 from "@/components/CompTV3";


export default {
  name: "TV2",

  props: [
    'pProductsTV1',
    'pProductsTV2',
    'pProductsTV3',
    'pChangeTVTime',
  ],

  components: {
    CompTV2,
    CompTV3,
    VideoBackground
  },
  data() {
    return {
      video: Flamme1,
      logo: logo,
    }

  },
  methods: {
  },
  async created() {
    if (process.env.VUE_APP_FIRMA_ID === "1"){
      setTimeout(()=>{window.location.href = 'https://www.koc-doner-welt.de/tv4'}, this.pChangeTVTime);
    }


    if (process.env.VUE_APP_FIRMA_ID === "2"){
      //setTimeout(()=>{window.location.href = 'https://www.koc2.koc-doner-welt.de/tv3'}, this.pChangeTVTime);
      setTimeout(()=>{window.location.href = 'https://www.koc2.koc-doner-welt.de/tv4'}, 70000);
    }
  }
}
</script>

<style scoped>
@import "../css/TV1.css";

</style>
