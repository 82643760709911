<template>
  <div id="app">
    <v-app>
      <!--    <TV1></TV1>-->


      <router-view
          :pProductsTV1="productsTV1"
          :pProductsTV2="productsTV2"
          :pProductsTV3="productsTV3"
          :pProductsTV4="productsTV4"
          :pProductsTV5="productsTV5"
          :pChangeTVTime="changeTVTime"
      />
    </v-app>
  </div>
</template>


<script>
import TV1 from "@/views/TV1";
import {getAll, getAllByFID} from "@/util/productUtil";

export default {

  components: {
    TV1
  },

  data() {
    return {
      product: "",
      productsTV1:{},
      productsTV2:{},
      productsTV3:{},
      productsTV4:{},
      productsTV5:{},

      // changeTVTime:50000
      changeTVTime:9999999
    }
  },


  async created() {
    //this.product = await getAll();
    this.product = await getAllByFID(process.env.VUE_APP_FIRMA_ID);

    for (let i = 0; i < this.product.category.length; i++) {
      this.productsTV1 = this.product.category.filter(item => item.tv ==="1");
      this.productsTV2 = this.product.category.filter(item => item.tv ==="2");
      this.productsTV3 = this.product.category.filter(item => item.tv ==="3");
      this.productsTV4 = this.product.category.filter(item => item.tv ==="4");
      this.productsTV5 = this.product.category.filter(item => item.tv ==="5");
    }
  }
}


</script>


<style>
body {
  margin: 0;
}

html {
  overflow: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin: 0 !important;
}

</style>
